export function register() {
  if ("serviceWorker" in navigator) {
    const onsuccess = () => console.log("[Service Worker] Registered");
    const onfailure = () => console.log("[Service Worker] Failed");

    navigator.serviceWorker
      .register(`${process.env.PUBLIC_URL}/service-worker.ts`)
      .then(onsuccess)
      .catch(onfailure);
  }
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}
