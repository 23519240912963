import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import useInstallPrompt from "./useInstallPrompt";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

type response = {
  key: string;
  values: string;
  description: string;
};

function App() {
  const [code, setCode] = useState("");
  const [result, setResult] = useState("");
  const [description, setDescription] = useState("");

  async function handleSubmit() {
    const response = await fetch(
      `https://code-mapper.iiapadoks.workers.dev/?key=${code}`
    );
    const json = (await response.json()) as response;
    setResult(json.values);
    setDescription(json.description);
  }

  var installPromptEvent = useInstallPrompt();

  function handleEnterPress(key: string) {
    if (key === "Enter") {
      handleSubmit();
    }
  }

  const handleInstallClick = () => {
    if (installPromptEvent) {
      // Show the browser install prompt
      installPromptEvent.prompt();

      // Handle the user's response
      installPromptEvent.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }
        // Clear the saved event
        installPromptEvent = null;
      });
    }
  };

  function handleCopy(text: string) {
    navigator.clipboard.writeText(text);
  }

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack>
        <TextField
          label="Code"
          onChange={(e) => setCode(e.target.value)}
          onKeyDown={(e) => handleEnterPress(e.key)}
        />
        <Button variant="contained" onClick={() => handleSubmit()}>
          Find
        </Button>
        <Box sx={{ height: "64px" }} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography fontSize="64px">{result}</Typography>
          <IconButton
            onClick={() => handleCopy(result)}
            sx={{
              display: result === "" || result === undefined ? "none" : "block",
            }}
          >
            <ContentCopyIcon fontSize="large" />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography fontSize="32px">{description}</Typography>
          <IconButton
            onClick={() => handleCopy(description)}
            sx={{
              display:
                description === "" || description === undefined
                  ? "none"
                  : "block",
            }}
          >
            <ContentCopyIcon />
          </IconButton>
        </Box>
        <Box sx={{ height: "64px" }} />
        {installPromptEvent && (
          <Button variant="outlined" onClick={handleInstallClick}>
            Install our app
          </Button>
        )}
      </Stack>
    </Box>
  );
}

export default App;
