import { useEffect, useState } from "react";

function useInstallPrompt(): BeforeInstallPromptEvent | null {
  const [installPromptEvent, setInstallPromptEvent] =
    useState<BeforeInstallPromptEvent | null>(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event: BeforeInstallPromptEvent) => {
      // Prevent the default browser prompt
      event.preventDefault();
      // Save the event for later use
      setInstallPromptEvent(event);
    };

    window.addEventListener(
      "beforeinstallprompt",
      handleBeforeInstallPrompt as EventListener
    );

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt as EventListener
      );
    };
  }, []);

  return installPromptEvent;
}

export default useInstallPrompt;
